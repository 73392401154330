import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { take } from 'rxjs/operators';

import {
	CreateFlexPeriodAction,
	DeleteFlexPeriodAction,
	GetFlexPeriodsAction,
	UpdateFlexPeriodAction,
} from 'app/ngrx/flex-periods/flex-periods.actions';
import { MilitaryTimeStringPipe } from 'app/pipes/military-time-string.pipe';
import { AppState } from '../ngrx/app-state/app-state';
import { getFlexPeriodById, getFlexPeriodCollection } from '../ngrx/flex-periods/flex-periods.selectors';
import { HttpService } from './http-service';

export interface FlexPeriod {
	id: number;
	name: string;
	schedules: FlexSchedule[];
	deleted_at?: string;
	created_at?: string;
}

export interface FlexSchedule {
	days_of_week: number[];
	start_hour: number;
	start_minute: number;
	end_hour: number;
	end_minute: number;
}

export interface AddFlexScheduleRequest extends FlexSchedule {
	flex_period?: number;
	name?: string;
	schedules: FlexSchedule[];
}

export enum DaysOfWeek {
	Sun,
	Mon,
	Tue,
	Wed,
	Thu,
	Fri,
	Sat,
}

@Injectable({
	providedIn: 'root',
})
export class FlexPeriodService {
	constructor(private http: HttpService, private store: Store<AppState>) {}

	//#region ngrx

	flexPeriods$ = this.store.select(getFlexPeriodCollection);
	flexPeriod$ = (id: number) => this.store.pipe(select(getFlexPeriodById(id)));

	RefreshFlexPeriodsIfEmpty() {
		this.flexPeriods$.pipe(take(1)).subscribe((flexPeriods) => {
			if (flexPeriods.length === 0) {
				this.RefreshFlexPeriods();
			}
		});
	}

	RefreshFlexPeriods() {
		this.store.dispatch(GetFlexPeriodsAction());
	}

	CreateFlexPeriod(flexPeriod: FlexPeriod) {
		this.store.dispatch(CreateFlexPeriodAction({ flexPeriod }));
	}

	UpdateFlexPeriod(id: number, partial: Partial<FlexPeriod>) {
		this.store
			.select(getFlexPeriodById(id))
			.pipe(take(1))
			.subscribe((original) => {
				const update = { id, changes: partial };
				this.store.dispatch(UpdateFlexPeriodAction({ update, original }));
			});
	}

	DeleteFlexPeriod(id: number) {
		this.store
			.select(getFlexPeriodById(id))
			.pipe(take(1))
			.subscribe((toRemove) => {
				this.store.dispatch(DeleteFlexPeriodAction({ id, toRemove }));
			});
	}

	//#endregion

	//#region HTTP

	AddFlexPeriodHTTP(body: AddFlexScheduleRequest) {
		return this.http.post<FlexPeriod>('v2/flex_period/schedules/add', body, undefined, false);
	}

	EditFlexPeriodHTTP(body: Partial<FlexPeriod>) {
		return this.http.post<FlexPeriod>('v2/flex_period/update', body, undefined, false);
	}

	RemoveScheduleHTTP(id: number) {
		return this.http.post<FlexPeriod>('v2/flex_period/schedules/remove', { id: id }, undefined, false);
	}

	DeleteFlexPeriodHTTP(id: number) {
		return this.http.post<object>('v2/flex_period/remove', { id: id }, undefined, false);
	}

	GetAllFlexPeriodsHTTP() {
		return this.http.post<FlexPeriod[]>('v2/flex_period/list', {}, undefined, false);
	}

	//#endregion

	ScheduleItemString(schedule: FlexSchedule): string {
		const milTimePipe = new MilitaryTimeStringPipe();

		const days = schedule.days_of_week.map((day) => DaysOfWeek[day]).join(', ');
		const startTime = milTimePipe.transform(schedule.start_hour + ':' + schedule.start_minute);
		const endTime = milTimePipe.transform(schedule.end_hour + ':' + schedule.end_minute);

		return `Every ${days}, ${startTime} - ${endTime}`;
	}
}
