import { Component, ElementRef, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, takeUntil, tap } from 'rxjs/operators';
import { FlexOptionSelect } from '../admin/flex-period/flex-period.component';
import { UNANIMATED_CONTAINER } from '../consent-menu-overlay';
import { ConsentMenuComponent } from '../consent-menu/consent-menu.component';
import { FlexPeriod, FlexPeriodService } from '../services/flex-period.service';
import {
	DAYS_SHOW_ACTIVITY_FUTURE_TEACHER,
	SchoolActivity,
	SchoolActivityInstance,
	SchoolActivityInstancesReq,
	SchoolActivityService,
} from '../services/school-activity.service';
import { UserService } from '../services/user.service';
import { ConfirmationDialogComponent, RecommendedDialogConfig } from '../shared/shared-components/confirmation-dialog/confirmation-dialog.component';

declare const window: Window & typeof globalThis & { Intercom: (_: string) => void };

export interface DeleteInstancePayload {
	event: MouseEvent;
	instance: SchoolActivityInstance;
}
export type ActivityInstanceData = {
	upcoming: SchoolActivityInstance[];
	past: SchoolActivityInstance[];
	flexPeriod: FlexPeriod;
	selected: SchoolActivityInstance | undefined;
};

@Component({
	selector: 'sp-teacher-activity',
	templateUrl: './teacher-activity.component.html',
	styleUrls: ['./teacher-activity.component.scss'],
})
export class TeacherActivityComponent implements OnInit, OnDestroy {
	closeDialog: () => void = () => {
		/* empty init */
	};
	// flexPeriod: FlexPeriod | undefined;
	teacherName = 'Instructor';
	emitInitialSelectedInstance = true;
	showDeleteInstance = true;
	teacherIcon = '';
	today: Date = new Date();
	displayUpcoming = false;
	initialLoad = true;
	activityInstanceData$: Observable<ActivityInstanceData> | null = null;
	private destroy$: Subject<void> = new Subject<void>();

	@ViewChild('deleteActivityDialogBody') deleteActivityDialogBody: TemplateRef<HTMLElement> | undefined;
	@ViewChild('deleteInstanceDialogBody') deleteInstanceDialogBody: TemplateRef<HTMLElement> | undefined;

	constructor(
		public flexService: FlexPeriodService,
		private userService: UserService,
		public activityService: SchoolActivityService,
		private dialog: MatDialog,
		@Inject(MAT_DIALOG_DATA)
		public data: {
			activity: SchoolActivity;
			day?: Date;
		}
	) {}

	ngOnInit(): void {
		this.today.setHours(0, 0, 0);
		this.flexService.RefreshFlexPeriodsIfEmpty();
		this.activityService.GetActivitiesHTTP();
		this.activityInstanceData$ = this.flexService.flexPeriod$(this.data.activity.flex_period_id).pipe(
			filter((fp) => !!fp),
			switchMap((flexPeriod) => {
				const data: SchoolActivityInstancesReq = {
					from: new Date(this.data.activity.created_at!),
					to: new Date(this.today.getFullYear(), this.today.getMonth(), this.today.getDate() + DAYS_SHOW_ACTIVITY_FUTURE_TEACHER),
					activity: this.data.activity,
					flexPeriod: flexPeriod,
					timezone: this.userService.getUserSchool().timezone,
				};
				this.activityService.GetActivityInstancesByIdAndFillExtra(data);
				return combineLatest([
					of(flexPeriod),
					this.activityService.upcomingInstances$(this.today).pipe(startWith([])),
					this.activityService.pastInstances$(this.today).pipe(
						startWith([]),
						map((pi) => {
							if (this.data.activity.status === 'archived') {
								return pi.filter((instance) => instance?.id !== 0);
							}
							return pi.filter((instance) => instance !== undefined) as SchoolActivityInstance[];
						})
					),
					this.activityService.selectedInstance$,
				]);
			}),
			distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
			map(([flexPeriod, upcoming, past, selected]) => {
				if (this.data.day && upcoming[0] && !selected && this.initialLoad) {
					const initialDay = new Date(this.data.day);
					const initialDayInstance = upcoming.find((instance) => {
						const instanceDate = new Date(instance!.start_time);
						return (
							instanceDate.getFullYear() === initialDay.getFullYear() &&
							instanceDate.getMonth() === initialDay.getMonth() &&
							instanceDate.getDate() === initialDay.getDate()
						);
					});
					if (initialDayInstance) {
						this.activityService.SelectActivityInstance(initialDayInstance.id);
					}
					this.initialLoad = false;
				}
				this.displayUpcoming = !selected;
				const upcomingInstances = upcoming || [];
				const pastInstances = past || [];
				const instanceData: ActivityInstanceData = {
					flexPeriod: flexPeriod!,
					upcoming: upcomingInstances.filter((i) => i !== undefined) as SchoolActivityInstance[],
					past: pastInstances.filter((i) => i !== undefined) as SchoolActivityInstance[],
					selected: selected ? selected : undefined,
				};
				return instanceData;
			})
		);

		combineLatest([this.userService.getUserById(this.data.activity.user_id), this.userService.isEnableProfilePictures$])
			.pipe(takeUntil(this.destroy$))
			.subscribe(([user, picsEnabled]) => {
				this.teacherName = user.display_name;
				if (picsEnabled && user.profile_picture) {
					this.teacherIcon = user.profile_picture;
				}
			});
	}

	deleteActivity(id: number): void {
		this.activityService.DeleteActivity(id);
		this.closeDialog();
	}

	deleteInstance(instance: SchoolActivityInstance): void {
		if (instance.id > 0) {
			this.activityService.DeleteActivityInstance(instance.id);
		} else if (this.data.activity.id) {
			this.activityService.CreateActivityInstance(new Date(instance.start_time), new Date(instance.end_time), this.data.activity.id, 'canceled');
			this.activityService.DeleteActivityInstanceFromStore(instance.id);
			this.activityService.ClearSelectedActivityInstance();
		}
	}

	openDeleteActivity(event: MouseEvent, id: number) {
		const target = new ElementRef(event.currentTarget);
		const options = [{ display: 'Remove Activity', color: '#7083A0', action: FlexOptionSelect.DeleteActivity }];

		UNANIMATED_CONTAINER.next(true);
		const cancelDialog = this.dialog.open(ConsentMenuComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: { header: '', options: options, trigger: target, adjustForScroll: true },
		});

		cancelDialog
			.afterClosed()
			.pipe(tap(() => UNANIMATED_CONTAINER.next(false)))
			.subscribe((deleteAction) => {
				if (deleteAction === FlexOptionSelect.DeleteActivity) {
					this.dialog
						.open(ConfirmationDialogComponent, {
							...RecommendedDialogConfig,
							width: '450px',
							data: {
								headerText: 'Remove the activity?',
								body: this.deleteActivityDialogBody,
								buttons: {
									confirmText: 'Remove activity',
									denyText: 'Cancel',
								},
								templateData: {},
							},
						})
						.afterClosed()
						.subscribe((deleteConfirmed: boolean) => {
							if (deleteConfirmed) {
								this.deleteActivity(id);
							}
						});
				}
			});
	}

	openDeleteInstance(data: DeleteInstancePayload): void {
		const target = new ElementRef(data.event.currentTarget);
		const options = [{ display: 'Delete Session', color: '#7083A0', action: FlexOptionSelect.DeleteInstance }];

		UNANIMATED_CONTAINER.next(true);
		const cancelDialog = this.dialog.open(ConsentMenuComponent, {
			panelClass: 'consent-dialog-container',
			backdropClass: 'invis-backdrop',
			data: { header: '', options: options, trigger: target, adjustForScroll: true },
		});

		cancelDialog
			.afterClosed()
			.pipe(tap(() => UNANIMATED_CONTAINER.next(false)))
			.subscribe((action) => {
				if (action === FlexOptionSelect.DeleteInstance) {
					this.dialog
						.open(ConfirmationDialogComponent, {
							...RecommendedDialogConfig,
							width: '450px',
							data: {
								headerText: 'Remove the session?',
								body: this.deleteInstanceDialogBody,
								buttons: {
									confirmText: 'Remove session',
									denyText: 'Cancel',
								},
								templateData: {},
							},
						})
						.afterClosed()
						.subscribe((deleteConfirmed: boolean) => {
							if (deleteConfirmed) {
								this.deleteInstance(data.instance);
							}
						});
				}
			});
	}

	updateShowDeleteInstance(show: boolean): void {
		this.showDeleteInstance = show;
	}

	openIntercom() {
		window.Intercom('show');
	}

	ngOnDestroy(): void {
		this.activityService.ClearActivityInstancesStore();
		this.activityService.ClearActivityAttendeesStore();
		this.activityService.ClearSelectedActivityInstance();
	}
}
